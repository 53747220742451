import axios from 'axios';
import { getLang } from '../utils/language';

 async function refreshAccessToken() {
  try {
    await axios.post(`${import.meta.env.VITE_API_URL}/users/refresh-token`, {}, { withCredentials: true });
  } catch (error) {
    console.error('Error refreshing token:', error);
    await axios.post('/auth/logout');
    window.location.href = '/sign-in';
    throw error;
  }
}

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    const lang = getLang();
    config.headers['Accept-Language'] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
