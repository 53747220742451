import api from './api';

export const signUp = async (user) => {
  try {
    const response = await api.post('/auth/register', user);

    return response.data;
  } catch (error) {
    console.error('Error during sign up:', error.response?.data?.error);
    return { error: error.response?.data?.error };
  }
};

export const logout = async () => {
  try {
    const response = await api.post('/auth/logout');

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error during sign in.');
    }
  } catch (error) {
    console.error('Error during logout:', error);
    return { error: error.response?.data?.error };
  }
};

export const login = async (user) => {
  try {
    const response = await api.post('/auth/login', user);

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error during sign in.');
    }
  } catch (error) {
    console.error('Error during sign in:', error.response?.data?.error);
    return { error: error.response?.data?.error };
  }
};

export const forgotPassword = async (user) => {
  try {
    const response = await api.post('/auth/forgot-password', user);

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error recovering password.');
    }
  } catch (error) {
    console.error('Error recovering password:', error.response?.data?.error);
    return { error: error.response?.data?.error };
  }
};

export const resetPassword = async (user) => {
  try {
    const response = await api.post('/auth/reset-password', user);

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error reseting password.');
    }
  } catch (error) {
    console.error('Error reseting password:', error.response?.data?.error);
    return { error: error.response?.data?.error };
  }
};

export const resendVerification = async (user) => {
  try {
    const response = await api.post('/auth/resend-verification', user);

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error resending verification.');
    }
  } catch (error) {
    console.error('Error resending verification:', error.response?.data?.error);
    return { error: error.response?.data?.error };
  }
};
