import { createContext, useContext, useEffect, useState } from 'react';
import { checkUser } from '../services/user';
import { getLang } from '../utils/language';
import { isUserLogged } from '../utils/token';
import { logout } from '../services/auth';
import Cookies from 'js-cookie';

const AuthContext = createContext();

// eslint-disable-next-line
export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [lang, setLang] = useState('en');
  const [memories, setMemories] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const tokenExists = isUserLogged();

  useEffect(() => {
    const verifyUser = async () => {
      setIsLoading(true);
      const response = await checkUser();
      if (!response.error) {
        setLang(response.lang);
        setUser(response);
        setBoxes(response.boxes);
        setIsExpired(new Date(response?.subscription?.endDate) < new Date());
        setIsAuthenticated(response !== null);
        setIsUserVerified(response?.isEmailVerified);
      } else {
        setUser(null);
        setIsAuthenticated(false);
        setIsUserVerified(false);
        const browserLang = getLang();
        setLang(browserLang);  
      }
      setIsLoading(false);
    };


    if(tokenExists) {
      verifyUser();
    } else {
      setIsLoading(false);
    }
      
  }, [tokenExists]);


  const onLogout = async () => {
    setIsLoading(true);
    const response = await logout();

    Cookies.remove('userIsLogged')


    if (response.error) {
      setIsLoading(false);
      return console.error(response.error);
    }
    setUser(null);
    setIsLoading(false);
    window.location.href = '/sign-in';
  };

  const value = {
    user,
    lang,
    setLang,
    isAuthenticated,
    isUserVerified,
    onLogout,
    setMemories,
    memories,
    setBoxes,
    boxes,
    setUser,
    setIsAuthenticated,
    isLoading,
    isExpired,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
