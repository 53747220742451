import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import { AuthProvider } from './context/AuthProvider';
import Seo from './components/Seo.jsx';

ReactDOM.createRoot(document.getElementById('root')).render(
  <AuthProvider>
    <Seo />
    <App />
  </AuthProvider>
);
