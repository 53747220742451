import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import { useEffect, useState, Suspense, lazy } from 'react';
import * as Fathom from 'fathom-client';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import ProtectedRoute from './components/ProtectedPage';
import FathomScript from './components/FathomScript';
import Loading from './components/Loading';
import Seo from './components/Seo';

import { useAuth } from './context/AuthProvider';

import flattenMessages from './utils/flattenMessages';

const Home = lazy(() => import('./pages/Home'));
const SignUp = lazy(() => import('./pages/SignUp'));
const VerifyEmail = lazy(() => import('./pages/VerifyEmail'));
const SignIn = lazy(() => import('./pages/SignIn'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Profile = lazy(() => import('./pages/Profile'));
const Memories = lazy(() => import('./pages/Memories'));
const Friends = lazy(() => import('./pages/Friends'));
const Invite = lazy(() => import('./pages/Invite'));
const Settings = lazy(() => import('./pages/Settings'));
const PublicProfile = lazy(() => import('./pages/PublicProfile'));
const PublicBox = lazy(() => import('./pages/PublicBox'));
const OnThisDay = lazy(() => import('./pages/OnThisDay'));
const Terms = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Thanks = lazy(() => import('./pages/Thanks'));
const Help = lazy(() => import('./pages/Help'));
const Roadmap = lazy(() => import('./pages/Roadmap'));
const About = lazy(() => import('./pages/About'));
const Box = lazy(() => import('./pages/Box'));

import es from './locales/es.json';
import en from './locales/en.json';

import './styles/main.css';

Fathom.load(import.meta.env.VITE_FATHOM_SITE_ID);

const locales = {
  en: flattenMessages(en),
  es: flattenMessages(es),
};

function App() {
  const { lang } = useAuth();
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    if (lang) {
      setLocale(lang);
    }
  }, [lang]);

  return (
    <IntlProvider locale={locale} messages={locales[locale]}>
      <Seo
        title={locales[locale]['seo.home']}
        description={locales[locale]['seo.home_description']}
        lang={lang}
      />

      <BrowserRouter>
        <NavigationHandler />
        <FathomScript />
        <Suspense
          fallback={
            <div className="center">
              <Loading />
            </div>
          }
        >
          <Routes>
            <Route
              path="/memories"
              element={
                <ProtectedRoute>
                  <Memories />
                </ProtectedRoute>
              }
            />
            <Route
              path="/friends"
              element={
                <ProtectedRoute>
                  <Friends />
                </ProtectedRoute>
              }
            />
            <Route
              path="/box/:id"
              element={
                <ProtectedRoute>
                  <Box />
                </ProtectedRoute>
              }
            />
            <Route
              path="/onthisday"
              element={
                <ProtectedRoute>
                  <OnThisDay />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/account"
              element={
                <ProtectedRoute>
                  <Settings defaultActive="account" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/privacy"
              element={
                <ProtectedRoute>
                  <Settings defaultActive="privacy" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/notifications"
              element={
                <ProtectedRoute>
                  <Settings defaultActive="notifications" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/subscription"
              element={
                <ProtectedRoute>
                  <Settings defaultActive="subscription" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/danger-zone"
              element={
                <ProtectedRoute>
                  <Settings defaultActive="danger-zone" />
                </ProtectedRoute>
              }
            />

            <Route
              path="/thanks"
              element={
                <ProtectedRoute>
                  <Thanks />
                </ProtectedRoute>
              }
            />
            <Route path="/about" element={<About />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/invite" element={<Invite />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/help" element={<Help />} />
            <Route path="/user/:id" element={<PublicProfile />} />
            <Route path="/user/box/:id" element={<PublicBox />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;

function NavigationHandler() {
  const location = useLocation();

  useEffect(() => {
    Fathom.trackPageview();
  }, [location]);

  return null;
}
