import Cookies from 'js-cookie';


export const isUserLogged = () => {
  return Cookies.get('userIsLogged');
}

export const getTokenFromUrl = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('token');
};
