import { Helmet } from 'react-helmet';

export default function Seo({
  title = '',
  description = '',
  lang,
  noindex = false,
}) {
  return (
    <Helmet htmlAttributes={{ lang }}>
      <title>{title ? `${title} | Memos.land` : `Memos.land`}</title>
      <meta name="description" content={description} />
      {noindex && <meta name="robots" content="noindex" />}

      <link rel="canonical" href="https://memos.land/" />
      <link rel="icon" href="/favicon/favicon.ico" type="image/x-icon" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <meta name="apple-mobile-web-app-title" content="memos.land" />
      <meta name="application-name" content="memos.land" />
      <meta name="theme-color" content={'#0079ff'} />
      <meta property="og:url" content="https://memos.land/" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@memosland" />
      <meta name="twitter:domain" content="memos.land" />
      <meta name="twitter:image" content="/favicon/apple-touch-icon.png" />
      <meta name="twitter:site" content="@memosland" />
      <link rel="manifest" href="/manifest.json" />
      <meta property="og:locale" content={lang} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content="/favicon/apple-touch-icon.png" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="memos.land" />
    </Helmet>
  );
}
