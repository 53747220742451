import { useAuth } from '../context/AuthProvider';
import { Navigate } from 'react-router-dom';
import Loading from './Loading';
import Seo from './Seo';

import styles from '../styles/components/protected-route.module.css';

export default function ProtectedRoute({ children }) {
  const { isAuthenticated, isLoading } = useAuth();
  

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Loading />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <>
      <Seo noindex={true} />
      {children}
    </>
  );
}
