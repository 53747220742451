import { useEffect } from 'react';

export default function FathomScript() {
  useEffect(() => {

    const isDevelopment = import.meta.env.MODE === 'development';

    if (isDevelopment) {
      return;
    }
    
    // Crea un nuevo script element
    const script = document.createElement('script');
    script.src = 'https://cdn.usefathom.com/script.js';
    script.setAttribute('data-spa', 'auto');
    script.setAttribute('data-site', 'CIVBWKIB');
    script.defer = true;

    // Añade el script al documento
    document.body.appendChild(script);

    return () => {
      // Limpieza: remueve el script cuando el componente se desmonte
      document.body.removeChild(script);
    };
  }, []);

  return null;
}
