import api from './api';

export async function checkUser() {
  try {
    const response = await api.get('/users/me');
    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error trying to get user.');
    }
  } catch (error) {
    console.error('Error getting user:', error.response ? error.response.data.error : error.message);
    return {
      error: error.response ? error.response.data.error : error.message,
    };
  }
}


export async function updateUser(data) {
  try {
    const response = await api.put(
      `${import.meta.env.VITE_API_URL}/users/me`,
      data
    );

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error trying to update user.');
    }
  } catch (error) {
    console.error(
      'Error updating user:',
      error.response ? error.response.data.error : error.message
    );
    return {
      error: error.response ? error.response.data.error : error.message,
    };
  }
}

export async function generateInvitationCode() {
  try {
    const response = await api.post(
      `${import.meta.env.VITE_API_URL}/users/generate-invitation-code`
    );

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error trying to generate invitacion code.');
    }
  } catch (error) {
    console.error(
      'Error generating invitacion code:',
      error.response ? error.response.data.error : error.message
    );
    return {
      error: error.response ? error.response.data.error : error.message,
    };
  }
}

export async function deleteUser() {
  try {
    const response = await api.delete(
      `${import.meta.env.VITE_API_URL}/users/me`
    );

    if (response.status === 204) {
      return response.data;
    } else {
      throw new Error('Error trying to delete user.');
    }
  } catch (error) {
    console.error(
      'Error deleting user:',
      error.response ? error.response.data.error : error.message
    );
    return {
      error: error.response ? error.response.data.error : error.message,
    };
  }
}

export async function getInvitation(code) {
  try {
    const response = await api.get(
      `${import.meta.env.VITE_API_URL}/users/invitation/${code}`
    );

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error trying to get invitation.');
    }
  } catch (error) {
    console.error(
      'Error getting invitation:',
      error.response ? error.response.data.error : error.message
    );
    return {
      error: error.response ? error.response.data.error : error.message,
    };
  }
}

export async function getFriends() {
  try {
    const response = await api.get(
      `${import.meta.env.VITE_API_URL}/users/friends`
    );

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error trying to get friends.');
    }
  } catch (error) {
    console.error(
      'Error getting friends:',
      error.response ? error.response.data.error : error.message
    );
    return {
      error: error.response ? error.response.data.error : error.message,
    };
  }
}

export async function getPublicMemories(id) {
  try {
    const response = await api.get(
      `${import.meta.env.VITE_API_URL}/users/profile/${id}`
    );

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error trying to get public information.');
    }
  } catch (error) {
    console.error(
      'Error getting public information:',
      error.response.data.error
    );
    return { error: error.response.data.error };
  }
}

export async function deleteFriend({ id }) {
  try {
    const response = await api.delete(
      `${import.meta.env.VITE_API_URL}/users/remove-friend/${id}`
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error('Error trying to delete friend.');
    }
  } catch (error) {
    console.error(
      'Error deleting friend:',
      error.response ? error.response.data.error : error.message
    );
    return {
      error: error.response ? error.response.data.error : error.message,
    };
  }
}

export async function acceptUserInvitation({ code }) {
  try {
    const response = await api.post(
      `${import.meta.env.VITE_API_URL}/users/accept-invitation/${code}`
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error('Error trying to accept invitation.');
    }
  } catch (error) {
    console.error(
      'Error accepting invitation:',
      error.response ? error.response.data.error : error.message
    );
    return {
      error: error.response ? error.response.data.error : error.message,
    };
  }
}

export async function checkUserEmail(token) {
  try {
    const response = await api.post(
      `${import.meta.env.VITE_API_URL}/users/verify-email`,
      { token }
      
    );

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error('Error trying to verify email.');
    }
  } catch (error) {
    console.error('Error verifying email:', error.response.data.error);
    return { error: error.response.data.error };
  }
}